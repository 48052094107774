<template>
  <BaseContainer>
    <MagicLink />
  </BaseContainer>
</template>

<script>
import MagicLink from '@components/Auth/MagicLink'

export default {
  components: {
    MagicLink
  }
}
</script>
